import { atom } from "jotai";

const getLocalStorageValue = (key, defaultValue) => {
  // return defaultValue;
  if (typeof window !== undefined) {
    try {
      const userSetValue = localStorage.getItem(key);
      // console.log("localStorage", key, userSetValue);
      return userSetValue ? JSON.parse(userSetValue) : defaultValue;
    } catch (e) {
      return false;
    }
  }
  return defaultValue;
};

export const localStorageAtom = (key, defaultValue) => {
  return atom(getLocalStorageValue(key, defaultValue));
};

export const betaFeaturesAtom = localStorageAtom("betaFeatures", false);
