import React, { useEffect } from "react";
import { whatIsLocalTier } from "../assets/homepageData";

export default function HowItWorks({ data }) {
  return (
    <div className={`grid grid-cols-3 gap-12 md:mt-20`}>
      {whatIsLocalTier.map((item, i) => (
        <div key={item.id} className="col-span-3 md:col-span-1 flex md:block">
          <div
            dangerouslySetInnerHTML={{ __html: item.icon }}
            className="mr-4 text-white -100 w-10 mt-2 md:mt-0"
          />
          <div className="flex-1 md:mt-1">
            <h4 className="text-base md:text-lg font-semibold font-display text-gray-800 mb-1">
              {item.title}
            </h4>
            <p className="text-gray-500 text-sm">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
