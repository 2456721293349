import React, { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { graphql, Link, useStaticQuery } from "gatsby";
import plumbingImg from "../images/plumbing-cover.jpeg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { howItWorksFeatured } from "../assets/homepageData";
import SEO from "../components/SEO";
import axios from "axios";
import HowItWorks from "../components/HowItWorks";

import { useAtom } from "jotai";
import { betaFeaturesAtom } from "../jotai-atoms";

function IndexPage() {
  const [trendingPages, setTrendingPages] = useState([]);
  const [hasBetaFeatures, updateBetaFeatureFlag] = useAtom(betaFeaturesAtom);

  const {
    allStrapiServiceCategory,
    allStrapiServiceAreaRank,
    allStrapiBlog,
    localTierPlaceHolderImage,
    heroImage,
  } = useStaticQuery(graphql`
    query home {
      localTierPlaceHolderImage: file(relativePath: { eq: "lt_main.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      heroImage: file(relativePath: { eq: "home_lt.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      featured: allStrapiServiceAreaRank(limit: 1) {
        edges {
          node {
            id
            matrixcontent
            city {
              id
              Name
              Slug
            }
            service {
              id
              Name
              Slug
            }
          }
        }
      }
      allStrapiServiceCategory {
        edges {
          node {
            id
            Name
            icon_name
            slug
          }
        }
      }
      allStrapiServiceAreaRank {
        edges {
          node {
            id
            matrixcontent
            city {
              id
              Name
              Slug
            }
            service {
              id
              Name
              Slug
              heroimage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 250, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
      allStrapiBlog(sort: { fields: published_at, order: DESC }) {
        edges {
          node {
            id
            Title
            Snippet
            metadescription
            metatitle
            slug
            published_at
            dateReadable: published_at(formatString: "MMMM DD, YYYY")
            image {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 350, placeholder: BLURRED)
                  seoImage: gatsbyImageData(
                    width: 1200
                    height: 630
                    quality: 100
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  function topKFrequent(nums, k) {
    let hash = {};

    for (let num of nums) {
      if (!hash[num]) hash[num] = 0;
      hash[num]++;
    }

    const hashToArray = Object.entries(hash);
    const sortedArray = hashToArray.sort((a, b) => b[1] - a[1]);
    const sortedElements = sortedArray.map((num) => parseInt(num[0]));
    return sortedElements.slice(0, k);
  }

  useEffect(() => {
    fetchTrendingEvents();

    async function fetchTrendingEvents() {
      axios
        .get(`https://api.localtier.com/trending?_limit=3`)
        .then((response) => {
          let serviceAreaIds = [];
          let details = {};

          // Handle success.
          // console.log(response.data);
          // const result = response.data.filter(
          //   (response) => response.type === "PAGEVIEW"
          // );

          // for (let i = 0; i < result.length; i++) {
          //   serviceAreaIds.push(result[i].service_area_rank.id);
          //   Object.assign(details, {
          //     [result[i].service_area_rank.id]: [result[i]],
          //   });

          //   //details[result[i].service_area_rank.id] = {city: result[i].city.Name, service: result[i].service.Name, cityslug: result[i].city.Slug, serviceslug: result[i].ervice.Slug}
          // }

          // let topresults = topKFrequent(serviceAreaIds, 3);

          // let topThreeTrendingPages = [];
          // for (let j = 0; j < topresults.length; j++) {
          //   topThreeTrendingPages.push({
          //     label: details[topresults[j]][0].service.Name,
          //     link: `/${details[topresults[j]][0].city.Slug}/${
          //       details[topresults[j]][0].service.Slug
          //     }`,
          //   });
          // }
          // console.log("response", response);
          setTrendingPages(
            response.data.map(({ service, url }) => ({
              label: service.Name,
              link: url,
            }))
          );
        })
        .catch((error) => {
          // Handle error.
          console.log("An error occurred:", error.response);
        });
    }
  }, []);

  const heroImageData = getImage(heroImage);

  return (
    <Layout bg={"gray"} header="light">
      <SEO
        title={"LocalTier | Sacramento's #1 Small Business Blog"}
        description={
          "Local Tier is Your Premier Source for the Best Restaurants, Homes Service Companies, Local Contractors, Retail Shops and Products Made in Sacramento."
        }
      />
      <section className="bg-gray-50 hero-bg--lt">
        <div className="max-w-7xl mx-auto  px-4 py-5 sm:px-6 lg:px-8">
          <div className="flex flex-wrap">
            <div className={`w-full md:w-2/3 lg:w-1/2 py-4 md:py-8 md:mb-10`}>
              <h1 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-3">
                Helping Local Businesses in Sacramento Grow Through the Power of
                Blogging
              </h1>
              <p className="text-gray-500 font-normal mb-6 leading-relaxed text-lg">
                Residents and Search Engines Alike Count on Blogs to Learn
                What’s Happening in Your Local Area—Ensure Your Business is a
                Part of the Action!
              </p>
              <p className="font-display font-f font-normal leading-relaxed mb-6 text-gray-500">
                <b className="block text-black text-lg">Trending</b>
                {trendingPages.map((item, i) => (
                  <Link
                    to={`${item.link}${
                      item.link.charAt(item.link.length - 1) == "/" ? "" : "/"
                    }`}
                    className="flex items-center py-1 text-brand-500 text-base font-medium"
                    title={item.label}
                  >
                    {item.label}
                    <ArrowNarrowRightIcon className="h-5 w-5" />
                  </Link>
                ))}
                {trendingPages.length < 1 ? (
                  <>
                    <span className="flex items-center py-1 text-gray-200 text-base font-medium ">
                      <span className="rounded-xl bg-gray-200 w-64">
                        loading...
                      </span>
                    </span>
                    <span className="flex items-center py-1 text-gray-200 text-base font-medium ">
                      <span className="rounded-xl bg-gray-200 w-64">
                        loading...
                      </span>
                    </span>
                    <span className="flex items-center py-1 text-gray-200 text-base font-medium ">
                      <span className="rounded-xl bg-gray-200 w-64">
                        loading...
                      </span>
                    </span>
                  </>
                ) : null}
              </p>

              {/* <div>
                <label htmlFor="search" className="sr-only">
                  Search LocalTier
                </label>
                <input
                  type="text"
                  id="search"
                  className="max-w-xl shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full  border-gray-100 rounded-xl py-3 px-4  text-gray-500"
                  placeholder="Start searching in Sacramento"
                />
              </div> */}
            </div>

            <div className="w-full md:w-1/3 lg:w-1/2 md:pl-16 flex md:justify-end items-center">
              <GatsbyImage
                image={heroImageData}
                className="-mx-4 sm:-mx-6 mt-4 md:mt-0 rounded-none shadow-none -mb-5 md:mx-0 md:mb-0 max-h-60 md:shadow-xl md:max-h-80 md:rounded-lg overflow-hidden"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-100 relative">
        <div className="md:absolute md:h-20 md:-mt-20 w-full">
          <div className="sm:px-6 lg:px-8 max-w-7xl mx-auto flex flex-col md:flex-row gap-4 md:shadow-sm bg-white md:rounded-full px-4 md:px-0 py-10 md:py-6">
            {allStrapiServiceCategory.edges.map(({ node }) => (
              <Link
                key={node.id}
                to={node.slug}
                className="flex md:flex-col items-center align-center w-full space-x-5 md:space-x-0 md:space-y-2 space-y-0 brand-hover mb-2 md:mb-0"
              >
                <span>
                  <img
                    src={"./" + node.icon_name + ".svg"}
                    className="w-6 h-6 md:w-6 md:h-6"
                  />
                </span>
                <span className="text-base font-bold">{node.Name}</span>
              </Link>
            ))}
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-12 md:py-16 sm:px-6 lg:px-8">
          <HowItWorks />
        </div>
      </section>

      {/* <section className="bg-white bg-gradient-to-b from-white to-gray-50"> */}
      <section className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-16">
          <h2 className="text-3xl md:text-4xl font-display font-extrabold text-gray-900 text-center ">
            <span className="tier-underline text-brand-500">Top Tier</span>{" "}
            Businesses in Sacramento
          </h2>

          <div className="flex flex-wrap py-12">
            <div className="w-full md:w-1/3">
              {/* <div className="flex-shrink-0 flex pr-5 justify-center md:justify-start">
                {[...Array(5)].map((_, index) => (
                  <StarIcon
                    className="h-6 w-6 text-yellow-400"
                    aria-hidden="true"
                    key={index}
                  />
                ))}
              </div> */}
              <div className="justify-center md:justify-start text-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fad"
                  data-icon="user-friends"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  className="w-12 mx-auto md:m-0"
                >
                  <g className="fa-group">
                    <path
                      fill="currentColor"
                      d="M480 256a96 96 0 1 0-96-96 96 96 0 0 0 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592a48 48 0 0 0 48-48 111.94 111.94 0 0 0-112-112z"
                      className="text-gray-400"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M192 256A112 112 0 1 0 80 144a111.94 111.94 0 0 0 112 112zm76.8 32h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 0 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 268.8 288z"
                      className="text-gray-800"
                    ></path>
                  </g>
                </svg>
              </div>
              <div className="py-4 pb-6">
                <span className="font-display text-lg md:text-xl font-bold text-gray-900 mb-2 block text-center md:text-left">
                  What We Do
                </span>
                <p className="text-gray-500 text-center md:text-left">
                  Local Tier is all about spotlighting local
                  small-to-medium-sized businesses across California along with
                  the latest industry happenings. We deliver the latest local
                  business news, reviews, how-to’s, special announcements and
                  more to your community!{" "}
                </p>
                <Link
                  to="/about-localtier/"
                  className="block my-3 text-brand-500  text-center md:text-left"
                >
                  Learn more about LocalTier
                </Link>
              </div>{" "}
            </div>

            <div
              className={`w-full md:w-2/3 md:pl-12${
                hasBetaFeatures ? " flex content-center" : ""
              }`}
            >
              <div
                className={
                  "grid grid-cols-2 md:grid-cols-3 gap-6" +
                  (hasBetaFeatures ? " w-full" : "")
                }
              >
                {hasBetaFeatures &&
                  allStrapiServiceCategory.edges.map(({ node }) => (
                    <div
                      key={node.id}
                      className="flex space-x-4 items-center w-full"
                    >
                      <span>
                        <img
                          src={"./" + node.icon_name + ".svg"}
                          className="w-10 h-10"
                        />
                      </span>
                      <span className="text-2xl font-bold">{node.Name}</span>
                    </div>
                  ))}
                {!hasBetaFeatures &&
                  allStrapiServiceAreaRank.edges.map(({ node: post }, i) => {
                    const heroImage = getImage(
                      post.service &&
                        post.service.heroimage &&
                        post.service.heroimage.localFile
                    );
                    const placeHolderImageData = getImage(
                      localTierPlaceHolderImage
                    );

                    let slugWTrailingSlash = `/${post.city.Slug}/${post.service.Slug}/`;

                    return (
                      <div key={post.Name}>
                        <div className="flex-shrink-0 flex h-full h-40 flex-col rounded-lg shadow-md hover:shadow-lg transition-shadow overflow-hidden relative text-white hover:text-gray-100 focus:ring-brand-500 focus:border-brand-500 ">
                          <Link
                            to={slugWTrailingSlash}
                            className="hover:underline leading-none  focus:ring-brand-500 focus:border-brand-500 h-full"
                          >
                            <GatsbyImage
                              alt={`${post.city.Name} ${post.service.Name}`}
                              image={
                                heroImage ? heroImage : placeHolderImageData
                              }
                              className="h-full w-full object-cover"
                            />

                            <div className="absolute w-full bottom-0 bg-gradient-to-t from-black to-transparent p-1 py-3 pt-6 text-center font-medium leading-5 font-display">
                              {post.service.Name}
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50">
        <div className="max-w-7xl mx-auto  px-4   sm:px-6  lg:px-8 py-12 md:py-16">
          <h2 className="text-3xl md:text-4xl font-display font-extrabold text-gray-900 text-center mb-3">
            How it Works
          </h2>
          <p className="text-gray-500 max-w-4xl mx-auto md:text-center leading-relaxed">
            We work alongside local, high-quality, hand-picked writers and
            marketers across California who are deeply involved in helping local
            businesses. We manually review each company to make sure that we
            only share the very best brands that hold themselves to
            uncompromising standards. Each business is graded according to the
            following criteria:
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-8 mx-auto">
            {howItWorksFeatured.map((item, i) => (
              <div key={item.id} className="bg-white rounded-xl">
                <div className="flex p-6">
                  <div
                    dangerouslySetInnerHTML={{ __html: item.icon }}
                    className="mr-4 text-brand-200 w-12"
                  />
                  <div className="flex-1">
                    <h4 className="text-lg font-semibold font-display text-gray-800 mb-1">
                      {item.title}
                    </h4>
                    <p className="text-gray-500 text-sm">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <p className="text-gray-500 max-w-4xl mx-auto md:text-center leading-relaxed">
            These are the criteria by which we hold our writers and the
            businesses we feature, and top businesses are unwavering in their
            dedication to do and be their best. That’s why Local Tier is one of
            the fastest-growing sites in California for local business exposure,
            highlights, news and reviews!
          </p>
        </div>
      </section>

      <section className="bg-white">
        <div className="max-w-7xl mx-auto  px-4   sm:px-6  lg:px-8 py-12 md:py-16">
          <h2 className="text-3xl md:text-4xl font-display font-extrabold text-gray-900 text-center mb-8">
            From the Blog
          </h2>

          <div className="grid grid-cols-3 gap-8">
            {allStrapiBlog.edges.map(({ node: post }) => (
              <div
                key={post.id}
                className="overflow-hidden col-span-3 md:col-span-1"
              >
                <div>
                  <GatsbyImage
                    className="h-48 w-full object-cover rounded-xl mb-3"
                    image={getImage(post && post.image && post.image.localFile)}
                    alt=""
                  />
                </div>
                <p class="text-sm font-medium text-brand-600">
                  <span class="hover:underline">Article</span>
                </p>
                <Link className="block mt-2" to={`/blog/${post.slug}/`}>
                  <p class="text-xl font-semibold text-gray-900">
                    {post.Title}
                  </p>
                  <p class="mt-3 text-base text-gray-500">
                    {post.Snippet &&
                      post.Snippet.substr(0, post.Snippet.indexOf("."))}
                    .
                  </p>
                </Link>

                <div class="mt-6 flex items-center">
                  <div class="flex-shrink-0">
                    <a href="#">
                      <span class="sr-only">Christine Pilla</span>
                    </a>
                  </div>
                  <div>
                    <p class="text-sm font-medium text-gray-900">
                      Christine Pilla
                    </p>
                    <div class="flex space-x-1 text-sm text-gray-500">
                      <time datetime={post.published_at}>
                        {post.dateReadable}
                      </time>
                      <span aria-hidden="true">&middot;</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
